//
// Theme style
//
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  font-family: 'Quicksand', sans-serif;
}
// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
video {
  // padding: 0px !important;
  // background: black !important;
}

.video-container {
  aspect-ratio: 16 / 9;
  padding: 0px !important;
  background: black !important;

  @media (max-width: 600px) {
    aspect-ratio: 4 / 3 !important;
    // aspect-ratio: 9 / 16 !important;
  }
}
.video-btn {
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 38px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: rgba(0, 0, 0, 0.65);
}
.recording-indicator {
  padding: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.65);
}
.video-slider {
  width: 65%;
  height: 40px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
}
input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: #ddd;
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}

input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
}
.mute-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 999;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}
.video-slider--lg {
  width: 85%;
}
.cropper-handler {
  height: calc(100% + 22px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: orange;
  position: absolute;
  top: -11px;
  width: 10px;
  cursor: pointer;
}
.cropper-handler--left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.cropper-handler--right {
  left: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.cropper-pipe {
  height: 10.5px;
  width: 20px;
  background-color: pink;
  position: absolute;
  left: 20px;
  z-index: 999;
}
.cropper-pipe--top {
  top: -11px;
}
.cropper-pipe--bottom {
  bottom: -11px;
}

.text-secondary-dark {
  color: #898b9b !important;
}

.action-dropdown {
  z-index: 9999 !important;
  position: fixed !important;
  -webkit-overflow-scrolling: touch !important;
}

.video-recorder-element {
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg) !important;
  -moz-transform: rotateY(180deg) !important;
}
.bg-custom-success {
  background-color: #00c8bd;
}
.bg-custom-danger {
  background-color: #ff395b;
}
.wh-custom {
  width: 30px;
  height: 30px;
}
// video {
//   transform: scale(-1, 1) !important;
// }
// video::-webkit-media-controls-panel {
//   transform: scale(-1, 1) !important;
// }
// @media screen and (max-width: 767px) {
//   .video-recorder-element {
//     transform: rotate(90deg) !important;
//   }
// }

@media (max-width: 767.99px) {
  .btn-section {
    // margin-top: -30px !important;
    // padding-bottom: 50px !important;
    // margin-top: -15px !important;
    // padding-bottom: 20px !important;
    margin-top: -24px !important;
    margin-bottom: 30px !important;
  }
}

@media (max-width: 369.99px) {
  .btn-section {
    margin-top: -21px !important;
    margin-bottom: 30px !important;
    .btn {
      font-size: 11px !important;
    }
    h2 {
      font-size: 14px !important;
    }
  }
}
.error-text {
  color: #ee2d41 !important;
}

.button__Button-hkteey-0 {
  display: none !important;
}

@media (max-width: 991.99px) {
  .record-button__ButtonBorder-sc-1n5amwk-2 {
    width: 56px !important;
    height: 56px !important;
  }
  .record-button__Button-sc-1n5amwk-0 {
    width: 40px !important;
    height: 40px !important;
    margin-left: 0.5px !important;
  }
}

.video-upload-section {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  .fa-camera {
    font-size: 20px !important;
  }
}

//Alert Modal

.alert-modal {
  &__icon {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 3px solid red;
    &-success {
      border: 4px solid rgba(200, 244, 241, 255) !important;
      .fa-check {
        font-size: 40px !important;
        color: #58b7ae !important;
      }
    }

    &-error {
      border: 4px solid rgba(255, 224, 228, 255) !important;

      .fa-times {
        color: #ff4061 !important;
        font-size: 40px !important;
      }
    }

    &-warning {
      border: 4px solid rgba(255, 244, 222, 255) !important;

      .fa-exclamation {
        color: #ffa800 !important;
        font-size: 40px !important;
      }
    }
  }
}

.alert-modal {
  .modal-body {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  }
}

.breadcrumb-heading {
  display: flex;
  align-items: center;
  // height: 44px;
  min-height: 44px;
}

.custom-alert {
  position: fixed;
  border: none;
  z-index: 999;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 96%;
  max-width: 600px;
}

// Payment Custom Styling
.card-styling .card-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.card-styling .card-body {
  position: relative;
  height: 400px;
}
.card-styling .price-card-body {
  position: relative;
  height: 470px;
}
.card-styling .card-text {
  height: 80px;
}
@media screen and (min-width: 1199px) {
  .card-styling .card-text {
    height: 110px;
  }
}
// Payment Custom Styling

//Loading overlay
.full-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-checkbox[type='radio'] {
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
}
.search-checkbox[type='radio']:after {
  background-color: transparent;
  border-radius: 25px;
  content: '';
  display: block;
  height: 14px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 14px;
}
.search-checkbox[type='radio']:checked:after {
  background-color: #ff00ae;
}

.bell-circle {
  font-size: 8px !important;
  top: -5px;
  right: 50%;
  transform: translateX(50%);
}

.custom-textarea {
  margin-bottom: 10px;
  width: 100%;
  min-height: 20px;
  overflow: auto;
  margin-bottom: 5px;
}
.custom-textarea:focus-visible {
  outline: none !important;
}

.upload-video-text {
  position: relative;
  color: green !important;
  // z-index: 9999 !important;
  z-index: 9999 !important;
  font-size: 15px;
  justify-content: center;
  margin-bottom: 10px;
  top: -8px;
}